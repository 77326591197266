import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { globalSettings } from "services/globalSettings/globalSettingsService";

/**
 * Scrolls to anchor.
 * #test -> will jump to an element with id #anchor-test.
 * This "anchor-Manipulation" prevents the browser from jumping immediately to the 
 * (maybe wrong) position of the element. The one (and only) jump to the anchor is done
 * by the useEffect here.
 * 
 * Scrolling to an Element:
 * 1. Search for last-element on page.
 * 2. After last-element on page is found, check if any element is loaded async (like forms) and still loading.
 * 3. After all elements has loaded, perform the scroll to the element.
 * 4. To delay the scroll until 3., the real # is: anchor-richtext-11. While the browser only shows: #richtext-11.
 *
 * 
 * @param {number} additionalOffset added to offSet of globalSettings
 */
function useScrollTo(additionalOffset = 0) {
  const [hash, setHash] = useState("");

  const router = useRouter();
  const scrollToOffset = globalSettings.navigation.scrollToOffset || 0;

  // Note: Maybe worth looking into if this is still a problem:
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/hashchange_event
  // window.addEventListener("hashchange", function(e){
  //   console.log('hashchange1', window.location.hash )
  // });

  useEffect(() => {
    // Intervals
    let searchLastContentElement = null;
    let checkLoadingContentElements = null;

    // Set window.location.hash to State
    if (window.location.hash) {
      if ((!hash && window.location.hash) || window.location.hash !== hash) {
        setHash(window.location.hash);
      }
    }

    // Start process of scrolling
    if (hash && window.location.hash === hash) {
      const sanitizeHash = (rawHash) => {
        let sanitizedHash = rawHash;
        // Remove query-parameters like ?edit=true
        if (rawHash.indexOf("?") !== -1) {
          sanitizedHash = rawHash.split("?")[0];
        }
        sanitizedHash = sanitizedHash.split("#")[1];
        return `#anchor-${sanitizedHash}`;
      };

      const scrollToElement = () => {
        const element = document.querySelector(`${sanitizeHash(hash)}`);
        if (element) {
          // console.log(
          //   `Scroll to: ${sanitizeHash(hash)}: top: ${
          //    element.offsetTop - (scrollToOffset + additionalOffset)
          //  } [${
          //    element.offsetTop
          //  } - (${scrollToOffset} globalSettings + ${additionalOffset} SourceCode)]`
          // );
          // Actual Scroll:
          document
            .getElementById("layout")
            .classList.add("temporarilyDisableAnimation");
          window.scrollTo({
            behavior: globalSettings.scrolling?.scrollEffect || "smooth",
            top: element.offsetTop - (scrollToOffset + additionalOffset),
          });
        } else {
          console.log(
            `Couldn't find and scroll to: #anchor-${sanitizeHash(hash)}`
          );
        }
      };

      const searchForAsyncContentElements = () => {
        // Check for content-elements with data-is-loading-Attribute
        const asyncContentElements = document.querySelectorAll(
          ".content-element[data-is-loading]"
        );
        if (!asyncContentElements) {
          // No async content-element exists.
          scrollToElement();
        } else {
          // Check for content-elements with data-is-loading="true"
          let contentElementsStillLoading = document.querySelectorAll(
            'div[data-is-loading="true"]'
          );
          if (contentElementsStillLoading.length > 0) {
            let asyncContentCheckCount = 0;
            checkLoadingContentElements = setInterval(() => {
              contentElementsStillLoading = document.querySelectorAll(
                'div[data-is-loading="true"]'
              );
              asyncContentCheckCount++;
              if (
                contentElementsStillLoading.length === 0 ||
                asyncContentCheckCount > 5
              ) {
                clearInterval(checkLoadingContentElements);
                scrollToElement();
              }
            }, 300);
          } else {
            scrollToElement();
          }
        }
      };

      // Check for .last-element
      let lastContentElement = document.querySelectorAll(
        ".content-element.last-element"
      );
      if (lastContentElement.length === 0) {
        // Periodically search for .last-element
        let lastContentSearchCount = 0;
        searchLastContentElement = setInterval(() => {
          lastContentElement = document.querySelectorAll(
            ".content-element.last-element"
          );
          lastContentSearchCount++;
          if (lastContentElement.length > 0 || lastContentSearchCount > 5) {
            clearInterval(searchLastContentElement);
            searchForAsyncContentElements();
          }
        }, 300);
      } else {
        searchForAsyncContentElements();
      }
    }

    return () => {
      clearInterval(searchLastContentElement);
      clearInterval(checkLoadingContentElements);
    };
  }, [router, hash]);
}

export default useScrollTo;
