import Head from "next/head";
import { useRouter } from "next/router";
import {
  isI18nLocaleDefaultLocale,
  matchWithNexti18NextLocale,
} from "utils/util";

/**
 * This component is used for dynamic routes like [...url], [...newsUrl]
 *
 * If you want to add translation link tags to the head element for hardcoded pages
 * use LanguageHeadTags (non Dynamic)
 *
 * This component injects all localization (translation) <link> tags to the HTML head
 *
 * more information:
 * https://developers.google.com/search/docs/advanced/crawling/localized-versions?hl=de
 *
 * if the page is not translated the default page is displayed then this component
 * adds a canonical link to the head
 *
 * @param {String} props.locale the locale of the current displayed page (page.locale / news.locale)
 * @param {String} props.urlPrefix optional - could be "" / "news" or "/news" / 
 *  "events" or "/events" (optional beginning slash will be handled!) 
 *  NOTE: !!only works without trailing slash!!
 *  -> domain.com/[urlPrefix]/url
 * @param {String} props.url the actual url of the current displayed page (page.url / news.url)
 * @param {String} props.localizations the localizations array of the currently displayed page
 * @returns
 */
const LanguageHeadTagsDynamic = (props) => {
  const router = useRouter();
  const isNotTranslated =
    router.locale !== matchWithNexti18NextLocale(props.locale);

    const rootUrl = process.env.NEXT_PUBLIC_NEXTJS_URL;

    /**
     * the page param can be props or the object from the localizations
     * array containing {id: , locale: }
     *
     * @param {*} isDefaultLocale
     * @param {*} page
     * @returns
     */
    const buildHrefUrl = (isDefaultLocale, page) => {
      if (isDefaultLocale) {
        // domain.com/[optionalPrefix/]url
        return `${rootUrl}/${urlPrefix()}${props.url}`;
      } else {
        // domain.com/en/[optionalPrefix/]url
        return `${rootUrl}/${matchWithNexti18NextLocale(
          page.locale
        )}/${urlPrefix()}${props.url}`;
      }
    };

    const urlPrefix = () => {
      if (props.urlPrefix) {
        const urlPrefixNoBeginningSlash =
          props.urlPrefix && props.urlPrefix.startsWith("/")
            ? props.urlPrefix.substring(1)
            : props.urlPrefix;
        return `${urlPrefixNoBeginningSlash}/`;
      }
      return "";
    };

    return (
      <>
        {isNotTranslated && !props.customCanonicalTag ? (
          <Head>
            <link rel="canonical" href={buildHrefUrl(true, props)} />
          </Head>
        ) : (
          <Head>
            {/* current page */}
            {props.locale ? (
              <link
                rel="alternate"
                hrefLang={
                  isI18nLocaleDefaultLocale(props.locale)
                    ? "x-default"
                    : matchWithNexti18NextLocale(props.locale)
                }
                href={buildHrefUrl(
                  isI18nLocaleDefaultLocale(props.locale),
                  props
                )}
              />
            ) : null}
            {/* translations of the page (localizations array) */}
            {props.localizations &&
              props.localizations.map((translation, index) => {
                return (
                  <link
                    key={index}
                    rel="alternate"
                    hrefLang={
                      isI18nLocaleDefaultLocale(translation.locale)
                        ? "x-default"
                        : matchWithNexti18NextLocale(translation.locale)
                    }
                    href={buildHrefUrl(
                      isI18nLocaleDefaultLocale(translation.locale),
                      translation
                    )}
                  />
                );
              })}
          </Head>
        )}
      </>
    );
};

export default LanguageHeadTagsDynamic;
