import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  enableBeforeunload,
  getDraftPageAndInitEdit,
  getEventAndInitEdit,
  getJobofferAndInitEdit,
  getNewsAndInitEdit,
  setEditMode,
  setEditModeContentPage,
} from "store/actions";

/**
 * Start editmode hook
 *
 */
function useStartEditMode(startEditMode) {
  // prevent triggering edit mode everytime you leave the edit mode
  const [editModeStartedOnce, setEditModeStartedOnce] = useState(false);
  // remember the path where the auto start edit mode was triggered
  const [editModeStartedOncePath, setEditModeStartedOncePath] = useState("");

  const router = useRouter();
  const {
    newsUrl: routerQueryNewsUrl,
    eventUrl: routerQueryEventUrl,
    jobofferUrl: routerQueryJobofferUrl,
  } = router.query;

  const dispatch = useDispatch();
  const isCmsUserAuthenticated = useSelector(
    (state) => state.cms.isCmsUserAuthenticated
  );
  const editMode = useSelector((state) => state.cms.editMode);
  const currentContentPage = useSelector(
    (state) => state.cms.currentPage.contentPage
  );
  const currentNews = useSelector((state) => state.cms.currentPage.news);
  const currentEvent = useSelector((state) => state.cms.currentPage.event);
  const currentJoboffer = useSelector(
    (state) => state.cms.currentPage.joboffer
  );

  useEffect(() => {
    if (
      startEditMode &&
      isCmsUserAuthenticated &&
      !editMode &&
      // only start once or if the above is true and the router path has changed
      // then the editModeStartedOnce state will still be true
      (!editModeStartedOnce || editModeStartedOncePath !== router.asPath)
    ) {
      if (currentContentPage) {
        // start edit mode for a contentPage
        setEditModeStartedOnce(true);
        setEditModeStartedOncePath(router.asPath);
        dispatch(
          setEditModeContentPage(
            currentContentPage.url,
            currentContentPage.draftId
          )
        );
        dispatch(getDraftPageAndInitEdit(currentContentPage.draftId));
        dispatch(enableBeforeunload());
      } else if (currentNews) {
        // start edit mode for a newsPage
        setEditModeStartedOnce(true);
        setEditModeStartedOncePath(router.asPath);
        dispatch(setEditMode());
        dispatch(getNewsAndInitEdit(routerQueryNewsUrl));
        dispatch(enableBeforeunload());
      } else if (currentEvent) {
        setEditModeStartedOnce(true);
        setEditModeStartedOncePath(router.asPath);
        dispatch(setEditMode());
        dispatch(getEventAndInitEdit(routerQueryEventUrl));
        dispatch(enableBeforeunload());
      } else if (currentJoboffer) {
        setEditModeStartedOnce(true);
        setEditModeStartedOncePath(router.asPath);
        dispatch(setEditMode());
        dispatch(getJobofferAndInitEdit(routerQueryJobofferUrl));
        dispatch(enableBeforeunload());
        // expand for more cases
      }
    }
  }, [
    dispatch,
    startEditMode,
    editModeStartedOncePath,
    editModeStartedOnce,
    isCmsUserAuthenticated,
    editMode,
    currentNews,
    routerQueryNewsUrl,
    routerQueryEventUrl,
    routerQueryJobofferUrl,
    currentContentPage,
    currentEvent,
    currentJoboffer,
    router,
  ]);
}

export default useStartEditMode;
