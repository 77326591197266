import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPublishedIndicator } from "store/actions";

/**
 * Sets the published indicator state in the cms top bar
 *
 */
function usePublishedIndicator(published) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPublishedIndicator(published));
  }, [dispatch, published]);
}

export default usePublishedIndicator;
