import { useRouter } from "next/router";

/**
 * Returns some useful informations & page-Attributes as string that can be used as className.
 * For the moment
 *
 * @param {Object} page page-Object
 * @returns {String}
 */

const usePageClassNames = (page) => {
  const router = useRouter();

  return `${page && page.name ? page.name : ""}${
    page && page.isDefault ? " is-default" : ""
  }${page && page.isPrivate ? " is-private" : ""}${
    page && page.isPrivateDefault ? " is-private-default" : ""
  }${page && page.url ? ` url-${page.url}` : ""}${
    router.locale ? ` ${router.locale}` : ""
  }`;
};

export default usePageClassNames;
