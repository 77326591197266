import useScrollTo from "hooks/useScrollTo";
import { useTranslation } from "next-i18next";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { Fragment } from "react";
import { getRandomSlug } from "utils/util";
// NOTE: import contentelements dynamic to prevent loading unused contentelements:
const TextWithImage = dynamic(() =>
  import("components/contentelements/textWithImage/textWithImage")
);
const Headline = dynamic(() =>
  import("components/contentelements/headline/headline")
);
const Cards = dynamic(() => import("components/contentelements/cards/cards"));
const ImageContentElement = dynamic(() =>
  import("components/contentelements/imageContentElement/imageContentElement")
);
const Spacer = dynamic(() =>
  import("components/contentelements/spacer/spacer")
);
const Separator = dynamic(() =>
  import("components/contentelements/separator/separator")
);
const Richtext = dynamic(() =>
  import("components/contentelements/richtext/richtext")
);
const Gallery = dynamic(() =>
  import("components/contentelements/gallery/gallery")
);
const Carousel = dynamic(() =>
  import("components/contentelements/carousel/carousel")
);
const Downloads = dynamic(() =>
  import("components/contentelements/downloads/downloads")
);
const AccordionContentElement = dynamic(() =>
  import(
    "components/contentelements/accordionContentElement/accordionContentElement"
  )
);
const Form = dynamic(() => import("components/contentelements/form/form"));
const TextWithHeadline = dynamic(() =>
  import("components/contentelements/textWithHeadline/textWithHeadline")
);
const Title = dynamic(() => import("components/contentelements/title/title"));
const NewsHolder = dynamic(() =>
  import("components/contentelements/newsHolder/newsHolder")
);
const ButtonContentElement = dynamic(() =>
  import("components/contentelements/buttonContentElement/buttonContentElement")
);
const Multimedia = dynamic(() =>
  import("components/contentelements/multimedia/Multimedia")
);
const CmsPageWarning = dynamic(() =>
  import(
    "components/cms/contentManagerControls/cmsWarning/cmsPageWarning/cmsPageWarning"
  )
);
const ImageTicker = dynamic(() =>
  import("components/contentelements/imageTicker/imageTicker")
);
const ImgWithOverlay = dynamic(() =>
  import("components/contentelements/imgWithOverlay/imgWithOverlay")
);
const ContentCarousel = dynamic(() =>
  import("components/contentelements/contentCarousel/contentCarousel")
);
const StoryCarousel = dynamic(() =>
  import("components/contentelements/storyCarousel/storyCarousel")
);
const AMHeadline = dynamic(() =>
  import("components/contentelements/amheadline/amheadline")
);

const LoginContentElement = dynamic(() =>
  import("components/contentelements/loginContenElement/loginContentElement")
);

const HardcodedContentElement = dynamic(() =>
  import(
    "components/contentelements/hardcodedContentElement/hardcodedContentElement"
  )
);

const PageRenderer = ({
  data,
  contentElementSettings,
  showCmsPageWarnings,
  hardcodedContent,
  currentLocale,
  isMobile,
}) => {
  const router = useRouter();
  const { t: tCms } = useTranslation("cms");
  useScrollTo();

  const renderContentElement = (
    contentElement,
    index,
    isLastElement,
    isFirstElement
  ) => {
    if (contentElement !== null) {
      switch (contentElement.__component) {
        case "contentelements.textwithimage":
          return (
            <TextWithImage
              content={contentElement}
              position={index}
              settings={contentElementSettings.textwithimage}
              isLastElement={isLastElement}
              isMobile={isMobile}
              isFirstElement={isFirstElement}
            />
          );
        case "contentelements.textwithheadline":
          return (
            <TextWithHeadline
              content={contentElement}
              position={index}
              settings={contentElementSettings.textwithheadline}
              isLastElement={isLastElement}
              isMobile={isMobile}
              isFirstElement={isFirstElement}
            />
          );
        case "contentelements.headline":
          return (
            <Headline
              content={contentElement}
              position={index}
              settings={contentElementSettings.headline}
              isLastElement={isLastElement}
              isMobile={isMobile}
              isFirstElement={isFirstElement}
            />
          );
        case "contentelements.title":
          return (
            <Title
              content={contentElement}
              position={index}
              settings={contentElementSettings.title}
              isLastElement={isLastElement}
              isMobile={isMobile}
              isFirstElement={isFirstElement}
            />
          );
        case "contentelements.image":
          return (
            <ImageContentElement
              content={contentElement}
              position={index}
              settings={contentElementSettings?.image}
              isLastElement={isLastElement}
              isMobile={isMobile}
              isFirstElement={isFirstElement}
            />
          );
        case "contentelements.richtext":
          return (
            <Richtext
              content={contentElement}
              position={index}
              settings={contentElementSettings.richtext}
              isLastElement={isLastElement}
              isMobile={isMobile}
              isFirstElement={isFirstElement}
            />
          );
        case "contentelements.cards":
          return (
            <Cards
              content={contentElement}
              position={index}
              settings={contentElementSettings?.cards}
              isLastElement={isLastElement}
              isMobile={isMobile}
              isFirstElement={isFirstElement}
            />
          );
        case "contentelements.downloads":
          return (
            <Downloads
              content={contentElement}
              position={index}
              settings={contentElementSettings.downloads}
              isLastElement={isLastElement}
              isMobile={isMobile}
              isFirstElement={isFirstElement}
            />
          );
        case "contentelements.accordion":
          return (
            <AccordionContentElement
              content={contentElement}
              position={index}
              settings={contentElementSettings.accordion}
              isLastElement={isLastElement}
              isMobile={isMobile}
              isFirstElement={isFirstElement}
            />
          );
        case "contentelements.carousel":
          return (
            <Carousel
              content={contentElement}
              settings={contentElementSettings.carousel}
              position={index}
              isLastElement={isLastElement}
              isMobile={isMobile}
              isFirstElement={isFirstElement}
            />
          );
        case "contentelements.form":
          return (
            <Form
              content={contentElement}
              position={index}
              settings={contentElementSettings.form}
              isLastElement={isLastElement}
              isMobile={isMobile}
              isFirstElement={isFirstElement}
            />
          );
        case "contentelements.spacer":
          return (
            <Spacer
              content={contentElement}
              settings={contentElementSettings.spacer}
              position={index}
              isLastElement={isLastElement}
              isMobile={isMobile}
              isFirstElement={isFirstElement}
            />
          );
        case "contentelements.separator":
          return (
            <Separator
              content={contentElement}
              settings={contentElementSettings.separator}
              position={index}
              isLastElement={isLastElement}
              isMobile={isMobile}
              isFirstElement={isFirstElement}
            />
          );
        case "contentelements.gallery":
          return (
            <Gallery
              content={contentElement}
              position={index}
              settings={contentElementSettings.gallery}
              isLastElement={isLastElement}
              isFirstElement={isFirstElement}
            />
          );
        case "contentelements.button":
          return (
            <ButtonContentElement
              content={contentElement}
              settings={contentElementSettings.button}
              position={index}
              isLastElement={isLastElement}
              isMobile={isMobile}
              isFirstElement={isFirstElement}
            />
          );
        case "contentelements.newsholder":
          return (
            <NewsHolder
              content={contentElement}
              settings={contentElementSettings.newsholder}
              isLastElement={isLastElement}
              isMobile={isMobile}
              isFirstElement={isFirstElement}
            />
          );
        case "contentelements.multimedia":
          return (
            <Multimedia
              content={contentElement}
              settings={contentElementSettings.multimedia}
              position={index}
              isLastElement={isLastElement}
              isMobile={isMobile}
              isFirstElement={isFirstElement}
            />
          );
        case "contentelements.imageticker":
          return (
            <ImageTicker
              content={contentElement}
              settings={contentElementSettings.imageticker}
              position={index}
              isLastElement={isLastElement}
              isMobile={isMobile}
              isFirstElement={isFirstElement}
            />
          );
        case "contentelements.imgwithoverlay":
          return (
            <ImgWithOverlay
              content={contentElement}
              settings={contentElementSettings.imgwithoverlay}
              position={index}
              isLastElement={isLastElement}
              isMobile={isMobile}
              isFirstElement={isFirstElement}
            />
          );
        case "contentelements.contentcarousel":
          return (
            <ContentCarousel
              content={contentElement}
              settings={contentElementSettings.contentcarousel}
              position={index}
              isLastElement={isLastElement}
              isMobile={isMobile}
              isFirstElement={isFirstElement}
            />
          );
        case "contentelements.storycarousel":
          return (
            <StoryCarousel
              content={contentElement}
              settings={contentElementSettings.storycarousel}
              position={index}
              isLastElement={isLastElement}
              isMobile={isMobile}
              isFirstElement={isFirstElement}
            />
          );
        case "contentelements.amheadline":
          return (
            <AMHeadline
              content={contentElement}
              settings={contentElementSettings?.amheadline}
              position={index}
              isLastElement={isLastElement}
              isMobile={isMobile}
              isFirstElement={isFirstElement}
            />
          );
        case "contentelements.login":
          return (
            <LoginContentElement
              content={contentElement}
              position={index}
              settings={contentElementSettings?.login}
              isLastElement={isLastElement}
              isMobile={isMobile}
              isFirstElement={isFirstElement}
            />
          );
        case "contentelements.hardcodedcontent":
          return (
            <HardcodedContentElement
              content={contentElement}
              hardcodedContent={hardcodedContent}
              currentLocale={currentLocale}
              isLastElement={isLastElement}
              isMobile={isMobile}
              isFirstElement={isFirstElement}
              position={index}
            />
          );
        default:
          return (
            <>
              <pre style={{ height: "250px", overflow: "auto" }}>
                {JSON.stringify(contentElement, null, 2)}
              </pre>
            </>
          );
      }
    }
  };

  return (
    <div id="page" className="page">
      {showCmsPageWarnings ? (
        <CmsPageWarning>
          {data.published && data.isFallback ? (
            <>
              <strong>{tCms("hint")}: </strong>
              {tCms("pageDoesNotExistInLanguage", { locale: router.locale })}
              <br />
              {tCms("otherLanguageWillBeShown", {
                locale: process.env.NEXT_PUBLIC_DEFAULT_LOCALE,
              })}
            </>
          ) : null}
          {!data.published ? (
            <>
              <strong>{tCms("hint")}: </strong>
              {tCms("pageWasNotPublishedInLanguage", { locale: router.locale })}
              <br />
              {router.locale === "de" || data.isFallback
                ? tCms("showingErrorPageInstead")
                : tCms("showingGermanPageInstead")}
            </>
          ) : null}
        </CmsPageWarning>
      ) : null}
      {/* Requirement: published: false - should only display the warning */}
      {data.published ? (
        <>
          {data?.content?.map((pageElement, i) => {
            return (
              <Fragment
                key={`${data.url}-${pageElement.id}+${getRandomSlug()}`}
              >
                {renderContentElement(
                  pageElement,
                  i,
                  i === data.content.length - 1,
                  i === 0
                )}
              </Fragment>
            );
          })}
        </>
      ) : null}
    </div>
  );
};

export default PageRenderer;
